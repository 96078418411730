:root {
  --white: #f8f9fa;
  --black: #000;
  --app-red: #dc3545;
  --app-green: #198754;
  --guess-black: #212529;
  --guess-gray: #495057;
  --guess-yellow: #ffcd39;
  --guess-green: #4dd4ac;
  --guess-text-white: #dee2e6;
  --guess-text-highlight: #f8f9fa;
  --guess-text-red: #dc3545;
  --keyboard-gray: #6c757d;
  --keyboard-yellow: #ffcd39;
  --keyboard-green: #4dd4ac;
  --app-width: min(100vw, var(--app-max-width));
  --app-max-width: 600px;
}
html {
  height: 100%;
}
body {
  height: 100%;
  background: var(--black);
  color: var(--white);
}
#root {
  height: 100%;
}
p {
  margin: 0;
}
ul {
  margin: 0;
}
a {
  color: var(--white);
}
button {
  font-size: calc(var(--app-width) / 30);
  padding: calc(var(--app-width) / 75);
  color: var(--white);
  background-color: var(--black);
  border: 1px solid white;
  border-radius: 4px;
  cursor: pointer;
}

/* Game */
.game {
  height: 100%;
  transition: background-color 0.1s ease;
  font-family: "Outfit", sans-serif;
  font-size: calc(var(--app-width) / 30);
}
.game.wide {
  --app-width: var(--app-max-width);
}
.game.win {
  background: var(--app-green);
}
.game.lose {
  background: var(--app-red);
}
.game.color-blind .main {
  --guess-yellow: rgb(96, 165, 250);
  --guess-green: rgb(251, 146, 60);
  --keyboard-yellow: rgb(96, 165, 250);
  --keyboard-green: rgb(251, 146, 60);
}
.main {
  max-width: var(--app-max-width);
  height: 100%;
  margin: auto;

  display: grid;
  grid-template-rows: auto auto 1fr auto;
}
.game.wide .main {
  width: calc(var(--app-max-width) * 2);
  max-width: calc(var(--app-max-width) * 2);
}

.ad-box-wrapper {
  display: none;
}
.ad-box-wrapper.hidden {
  display: none;
}
.ad-box {
  display:none;
}

/* Header */
.header {
  padding: 4px 4px 0px 4px;
  display: grid;
  grid-template-rows: auto auto auto;
}
.row-1 {
  display: grid;
  grid-template-columns: auto auto auto 1fr auto auto auto auto;
  column-gap: 8px;
  align-items: center;
}
.mode-switch {
  font-size: calc(var(--app-width) / 40);
}
.title {
  margin: 0;
  text-align: center;
  font-size: calc(var(--app-width) / 22);
}
.game:not(.wide) .title.practice {
  font-size: calc(var(--app-width) / 24);
}
.game:not(.wide) .title.historical {
  font-size: calc(var(--app-width) / 30);
}
.icon {
  margin: 0;
  border: none;
  padding: 0;
  background: none;

  display: flex;
  align-items: center;
  justify-content: center;
}
.icon img {
  width: calc(var(--app-width) / 18.5);
}
.row-2 {
  display: flex;
  justify-content: space-between;
}
.cannot-win {
  color: var(--guess-text-red);
}
.timer {
  font-family: monospace;
}
.row-3 {
  display: grid;
  grid-template-columns: repeat(32, 1fr);
  gap: 1px;
  padding: 1px 0px;
}
.chip {
  background: var(--guess-black);
  padding: 0;
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--app-width) / 60);
  height: calc(var(--app-width) / 32);
}
.chip.complete {
  color: var(--black);
  background: var(--guess-green);
}
.chip.highlight {
  color: var(--black);
  background: var(--white);
}

/* Boards */
.boards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  padding: 8px 2px 8px;
  column-gap: 8px;
  row-gap: 24px;
  overflow-y: scroll;
}
.game.wide .boards {
  grid-template-columns: repeat(8, 1fr);
}

.board {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1px;
  padding: 1px;
  transition: background-color 0.1s ease;

  position: relative;
}

.game:not(.game.hide-completed-boards) .board {
  transform: translateZ(0);
}

.game:not(.game.hide-completed-boards) .board.complete {
  filter: contrast(0.5) brightness(0.25);
  transition: box-shadow 0.1s ease, background-color 0.1s ease, filter 0.1s ease;
}

.game.hide-completed-boards:not(.animate-hiding) .board.complete {
  display: none;
}

.game.hide-completed-boards.animate-hiding .board {
  transform: translateZ(0);
}

.game.hide-completed-boards.animate-hiding .board.complete {
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  animation-name: hide;
  animation-fill-mode: both;
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  99.99% {
    filter: contrast(0.5) brightness(0.5);
    opacity: 0;
    visibility: initial;
    display: initial;
    position: initial;
    top: initial;
  }

  100% {
    visibility: hidden;
    display: block;
    position: absolute;
    top: -10000px;
  }
}

.board.highlight {
  background-color: var(--guess-text-highlight);
  box-shadow: 0px 0px 0px 2px var(--guess-text-highlight);
}
.board.highlight .cell:not(.yellow):not(.green):not(.text-red) {
  color: var(--white);
}

.board .scroll-into-view {
  position: absolute;
  visibility: none;
  top: -4px;
  bottom: -4px;
  left: 0;
  right: 0;
}

.cell {
  height: calc(var(--app-width) * 0.045);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--guess-black);
  user-select: none;
  position: relative;
  color: var(--guess-text-white);
}
.cell.gray {
  background: var(--guess-gray);
}
.cell.yellow {
  color: var(--black);
  background: var(--guess-yellow);
}
.cell.green {
  color: var(--black);
  background: var(--guess-green);
}
.cell.text-red {
  color: var(--guess-text-red);
}

.letter {
  position: absolute;
  font-size: calc(var(--app-width) / 25);
  line-height: 0.75;
}

.input-wrapper {
  position: sticky;
  bottom: 0px;
  grid-column: 1/5;

  display: flex;
  justify-content: center;
  align-self: flex-end;
}
.input-wrapper.hidden {
  display: none;
}
.input-wrapper .cell {
  background: var(--black);
}
.game.wide .input-wrapper {
  grid-column: 1/9;
}

.input {
  background-color: var(--white);
  padding: 1px;
}

.input .word {
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(5, calc(var(--app-width) * 0.045));
}

/* Keyboard */
.keyboard {
  display: grid;
  height: calc(var(--app-width) * 0.325);
  grid-template-columns: repeat(10, 1fr);
  padding: 1px;
}
.keyboard.hidden {
  display: none;
}
.game.wide .keyboard {
  justify-self: center;
  width: var(--app-max-width);
}

.key {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--white);
  font-size: calc(var(--app-width) / 20);
  user-select: none;
  transition: background-color 0.1s ease, color 0.1s ease;
  margin: 1px;
}
.key.enter-1 {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -2px;
}
.key.enter-2 {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -2px;
}
.key.enter-3 {
  border-top: none;
  border-top-right-radius: 0;
  border-left: none;
  border-bottom-left-radius: 0;
}

/* Result */
.result {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: calc(var(--app-width) / 75);
  padding: calc(var(--app-width) / 75);
  font-size: calc(var(--app-width) / 30);
}
.result.hidden {
  display: none;
}

.share {
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: calc(var(--app-width) / 75);
}
.text {
  text-align: center;
  background-color: var(--black);
  color: white;
  border: 1px solid;
  font-size: calc(var(--app-width) / 50);

  margin: 0;
}
.text .emoji {
  width: calc(var(--app-width) / 50);
}
.words {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  column-gap: calc(var(--app-width) / 75);
  text-align: center;
}
.game.wide .words {
  grid-template-columns: repeat(8, 1fr);
}
.words .word {
  background: none;
  border: none;
  padding: 0;
  color: var(--white);
  cursor: pointer;
}
.kofi {
  text-align: center;
}
.kofi .emoji {
  width: calc(var(--app-width) / 30);
}
.legal {
  text-align: center;
}

.popup-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.popup-wrapper.hidden {
  display: none;
}
.popup {
  background-color: var(--black);
  border: 1px solid var(--white);
  border-radius: 8px;

  width: min(calc(var(--app-max-width) * 0.75), 100vw);
  padding: calc(var(--app-width) / 20);

  display: flex;
  flex-direction: column;
  overflow: auto;
}
.separator {
  width: 100%;
}
.close {
  margin-top: calc(var(--app-width) / 30);
}

/* Settings */
.group label {
  margin-left: calc(var(--app-width) / 75);
}

.group.animate-hiding {
  margin-left: calc(var(--app-width) / 25);
}

.group.animate-hiding.active {
  opacity: 0.5;
}

.group.hide-ads.disabled {
  opacity: 0.5;
}

.popup .hint {
  font-size: calc(var(--app-width) / 40);
}

.kofi-email-input {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  margin: 4px;
}

/* Stats */
.stats-title {
  text-align: center;
  font-size: calc(var(--app-width) / 25);
  margin-bottom: calc(var(--app-width) / 120);
}
.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  margin-bottom: calc(var(--app-width) / 120);
}
.stats-grid .value {
  font-size: calc(var(--app-width) / 15);
}

.stats-grid .label {
  font-size: calc(var(--app-width) / 40);
}
.stats-chart {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: calc(var(--app-width) / 120);
  font-size: calc(var(--app-width) / 40);
  margin-bottom: calc(var(--app-width) / 120);
}
.stats-chart .bar-wrapper {
  position: relative;
  height: calc(var(--app-width) / 30);
}
.stats-chart .bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: var(--app-green);

  text-align: right;
  padding-right: calc(var(--app-width) / 120);
}
.stats-times {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: calc(var(--app-width) / 40);
  margin-bottom: calc(var(--app-width) / 120);
}
.stats-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: calc(var(--app-width) / 40);
}
.stats-editor .buttons {
  display: grid;
  grid-template-columns: auto auto auto 1fr auto;
  gap: calc(var(--app-width) / 40);
}
.stats-editor .link {
  background: none;
  font-size: calc(var(--app-width) / 40);
  border: none;
  padding: 0;
  color: var(--white);
  text-decoration: underline;
  cursor: pointer;
}
.stats-editor:not(.expanded) .editor {
  display: none;
}
.stats-editor:not(.expanded) .hint {
  display: none;
}
